var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('div',{staticClass:"wrap-title"},[_c('h2',{staticClass:"title-h2 font-weight-bold"},[_vm._v(" Настройка интеграций ")]),_c('div',{staticStyle:{"flex-basis":"470px"}},[_c('v-btn',{staticStyle:{"margin-right":"10px"},attrs:{"color":"primary","max-width":"230","width":"100%"},on:{"click":function($event){return _vm.updateConfiguringIntegrations(null)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus-circle-outline ")]),_vm._v(" Добавить интеграцию ")],1),_c('v-btn',{attrs:{"color":"primary","max-width":"230","width":"100%"},on:{"click":function($event){return _vm.updateConfiguringWebhooks(null)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus-circle-outline ")]),_vm._v(" Добавить вебхук ")],1)],1)])])],1),_c('v-row',{attrs:{"align":"baseline"}},[_c('v-col',{staticClass:"text-h4 font-weight-bold",attrs:{"cols":"auto"}},[_vm._v(" ID программы лояльности: ")]),_c('v-col',{attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(_vm.programId))]),_c('v-btn',{attrs:{"icon":"","color":"primary","small":""},on:{"click":function($event){return _vm.copyClipboard(_vm.programId)}}},[_c('v-icon',[_vm._v(" $iconify_feather-copy ")])],1)],1)],1),_c('v-row',{staticClass:"table-wrap"},[_c('v-col',[_c('v-data-table',{staticClass:"elevation-0 custom-table",attrs:{"headers":_vm.headers,"items":_vm.integrationsDisplay,"hide-default-footer":"","item-class":'itemClass'},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('div',{staticClass:"desc-13-text font-weight-500 color-text-dark"},[_vm._v(" "+_vm._s(_vm.getDate(item.created_at))+" ")]),_c('div',{staticClass:"desc-11 font-weight-600 text-light-grey"},[_vm._v(" "+_vm._s('в\u00A0' + _vm.getTime(item.created_at))+" ")])])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('div',{staticClass:"desc-13-text font-weight-500 color-text-dark"},[_vm._v(" "+_vm._s(_vm.getDate(item.updated_at))+" ")]),_c('div',{staticClass:"desc-11 font-weight-600 text-light-grey"},[_vm._v(" "+_vm._s('в\u00A0' + _vm.getTime(item.updated_at))+" ")])])]}},{key:"item.copy",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"img-copy",on:{"click":function($event){return _vm.copyClipboard(item.secret, item.name + ': ключ скопирован в буфер')}}},[_c('v-img',{attrs:{"src":require("@/icons/svg/copy.svg")}})],1)]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{staticClass:"custom-switch",attrs:{"loading":item.changeActiveAction,"disabled":item.changeActiveAction,"inset":"","hide-details":""},on:{"change":function($event){return _vm.activeChange(item, $event)}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:"item.change",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"img-change",on:{"click":function($event){item.type == 'integration' ? _vm.updateConfiguringIntegrations(item) : _vm.updateConfiguringWebhooks(item)}}},[_c('v-img',{attrs:{"src":require("@/icons/svg/chevron-right.svg")}}),_vm._v(" "+_vm._s(item.change)+" ")],1)]}},{key:"item.log_level",fn:function(ref){
var item = ref.item;
return [(item.log_level)?_c('div',[_vm._v(" "+_vm._s(item.log_level)+" ")]):_c('div',[_vm._v(" - ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }