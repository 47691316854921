<template>
  <div>
    <v-row>
      <v-col>
        <div class="wrap-title">
          <h2 class="title-h2 font-weight-bold">
            Настройка интеграций
          </h2>
          <div style="flex-basis: 470px">
            <v-btn
              color="primary"
              max-width="230"
              width="100%"
              style="margin-right: 10px"
              @click="updateConfiguringIntegrations(null)"
            >
              <v-icon left>
                mdi-plus-circle-outline
              </v-icon>
              Добавить интеграцию
            </v-btn>
            <v-btn
              color="primary"
              max-width="230"
              width="100%"
              @click="updateConfiguringWebhooks(null)"
            >
              <v-icon left>
                mdi-plus-circle-outline
              </v-icon>
              Добавить вебхук
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row align="baseline">
      <v-col
        cols="auto"
        class="text-h4 font-weight-bold"
      >
        ID программы лояльности:
      </v-col><v-col cols="auto">
        <span>{{ programId }}</span>
        <v-btn
          icon
          color="primary"
          small
          @click="copyClipboard(programId)"
        >
          <v-icon>
            $iconify_feather-copy
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="table-wrap">
      <v-col>
        <v-data-table
          :headers="headers"
          :items="integrationsDisplay"
          hide-default-footer
          class="elevation-0 custom-table"
          :item-class="'itemClass'"
        >
          <template v-slot:item.created_at="{ item }">
            <div>
              <div class="desc-13-text font-weight-500 color-text-dark">
                {{ getDate(item.created_at) }}
              </div>
              <div class="desc-11 font-weight-600 text-light-grey">
                {{ 'в\u00A0' + getTime(item.created_at) }}
              </div>
            </div>
          </template>
          <template v-slot:item.updated_at="{ item }">
            <div>
              <div class="desc-13-text font-weight-500 color-text-dark">
                {{ getDate(item.updated_at) }}
              </div>
              <div class="desc-11 font-weight-600 text-light-grey">
                {{ 'в\u00A0' + getTime(item.updated_at) }}
              </div>
            </div>
          </template>
          <template v-slot:item.copy="{ item }">
            <div
              class="img-copy"
              @click="copyClipboard(item.secret, item.name + ': ключ скопирован в буфер')"
            >
              <v-img src="@/icons/svg/copy.svg" />
            </div>
          </template>
          <template v-slot:item.active="{ item }">
            <v-switch
              v-model="item.active"
              :loading="item.changeActiveAction"
              :disabled="item.changeActiveAction"
              inset
              hide-details
              class="custom-switch"
              @change="activeChange(item, $event)"
            />
          </template>
          <template v-slot:item.change="{ item }">
            <div
              class="img-change"
              @click="item.type == 'integration' ? updateConfiguringIntegrations(item) : updateConfiguringWebhooks(item)"
            >
              <v-img src="@/icons/svg/chevron-right.svg" />
              {{ item.change }}
            <!--              <img :src="require(`${item.operator.img}`)">-->
            </div>
          </template>
          <template v-slot:item.log_level="{ item }">
            <div v-if="item.log_level">
              {{ item.log_level }}
            </div>
            <div v-else>
              -
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex'
  import Vue from 'vue'
  import dateTimeFormat from '@/mixins/dateTimeFormat.js'
  import copyClipboard from '@/mixins/copyClipboard.js'

  export default {
    name: 'SettingInfoTable',
    mixins: [dateTimeFormat, copyClipboard],
    data () {
      return {
        headers: [
          {
            text: 'ID',
            align: 'start',
            value: 'id',
            width: '5em',
          },
          { text: 'Название', value: 'name' },
          { text: 'Ключ', value: 'secret', width: 1 },
          { text: '', value: 'copy', align: 'start', sortable: false },
          { text: 'Логирование', value: 'log_level', width: '10em' },
          { text: 'Создано', value: 'created_at', width: '9em' },
          { text: 'Обновлено', value: 'updated_at', width: '9em' },
          { text: '', value: 'active', sortable: false, width: 1 },
          { text: '', value: 'change', sortable: false, width: 1 },
        ],
        desserts: [],
      }
    },
    computed: {
      ...mapGetters({
        getCreateConfiguringIntegrations: 'configuringIntegrations/configuring_integrations/getCreateConfiguringIntegrations',
        getCreateConfiguringWebhooks: 'configuringIntegrations/configuring_integrations/getCreateConfiguringWebhooks',
        programId: 'programId',
      }),
      integrationsDisplay () {
        const result = []

        const integrations = this.getCreateConfiguringIntegrations.map(x => {
          Vue.set(x, 'active', !x.revoked)
          Vue.set(x, 'changeActiveAction', false)
          Vue.set(x, 'type', 'integration')
          return x
        })

        const webhooks = this.getCreateConfiguringWebhooks.map(x => {
          Vue.set(x, 'changeActiveAction', false)
          Vue.set(x, 'secret', x.url)
          Vue.set(x, 'type', 'webhook')
          return x
        })

        Array.prototype.push.apply(result, integrations)
        Array.prototype.push.apply(result, webhooks)
        console.log('RESULT')
        console.log(result)

        return result
      },
    },
    watch: {

    },
    methods: {
      ...mapMutations({
        setUpdateIntegration: 'configuringIntegrations/configuring_integrations/setUpdateIntegration',
        openNavigationRight: 'configuringIntegrations/configuring_integrations/openNavigationConfiguring',

        setUpdateWebhook: 'configuringIntegrations/configuring_integrations/setUpdateWebhook',
        openWebhookForm: 'configuringIntegrations/configuring_integrations/openWebhookConfiguring',
      }),

      updateConfiguringIntegrations (val) {
        this.setUpdateIntegration(val)
        this.openNavigationRight(true)
      },
      updateConfiguringWebhooks (val) {
        this.setUpdateWebhook(val)
        this.openWebhookForm(true)
      },
      async activeChange (item, active) {
        try {
          console.log('activeChange', item, active)
          item.changeActiveAction = true
          // await this.$sleep()
          if (item.type !== 'webhook') {
            await this.$store.dispatch('configuringIntegrations/configuring_integrations/SetRevokedClient', {
              id: item.id,
              revoked: !active,
            })
          } else {
            console.log('item.id')
            console.log(item.active)
            await this.$store.dispatch('configuringIntegrations/configuring_integrations/ToggleWebhookStatus', {
              webhook_id: item.id,
              status: item.active,
            })
          }
        } catch (error) {
          item.revoked = !item.revoked
        } finally {
          item.changeActiveAction = false
        }
      },
    },
  }
</script>

<style scoped>

  .table-wrap {
    /* max-width: 1024px; */
    width: 100%;
    margin: auto;
  }
  .wrap-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 34px;
  }
  .img-copy {
    cursor: pointer;
    width: 15px;
  }
  .img-change {
    cursor: pointer;
    width: 15px;
  }
</style>
